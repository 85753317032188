@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --swiper-pagination-color: #858e9b;
  --swiper-pagination-bullet-inactive-color: #858e9b40;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.swiper-pagination-bullet-active {
  scale: 1.2;
}

body {
  font-family: 'Work Sans', sans-serif;
  background: #131313;
}

.PhoneInputInput {
  background: none !important;
  outline: none;
}

/* one 38 */
/* two 33 */
/* three 67 */
.rc-slider-captcha-jigsaw-puzzle {
  /* height: 60px !important;
  width: 64px; */
  /* bottom: 38px; */
  /* transform: scale(0.9); */
}

.puzzleOne .rc-slider-captcha-jigsaw-puzzle {
  bottom: 38px;
}

.puzzleTwo .rc-slider-captcha-jigsaw-puzzle {
  bottom: 34px;
  transform: scale(1.12);
}

.puzzleThree .rc-slider-captcha-jigsaw-puzzle {
  bottom: 68px;
}

.puzzleFour .rc-slider-captcha-jigsaw-puzzle {
  bottom: 28.4px;
}

.rc-slider-captcha-jigsaw-bg {
}

.Toastify__progress-bar-theme--dark {
  background: #fad998 !important;
}

.Toastify__toast-theme--dark {
  background: #202020 !important;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #181a20;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #858e9b;
  border-radius: 3px;
}

.bg-gradient {
  /* background: linear-gradient(
    180deg,
    #fcdb9a 12.5%,
    #fad998 25%,
    #f3d292 37.5%,
    #e9c687 50%,
    #d9b578 62.5%,
    #c59f64 75%,
    #ac854c 87.5%,
    #997039 100%
  ); */
  background: #fcdb9a;
  background: linear-gradient(
    180deg,
    #fcdb9a 0%,
    #fad998 20%,
    #f3d292 40%,
    #e9c687 45%,
    #d9b578 50%,
    #c59f64 60%,
    #ac854c 70%,
    #997039 90%
  );
  background: -webkit-linear-gradient(
    180deg,
    #fcdb9a 0%,
    #fad998 20%,
    #f3d292 40%,
    #e9c687 45%,
    #d9b578 50%,
    #c59f64 60%,
    #ac854c 70%,
    #997039 90%
  );
  background: -moz-linear-gradient(
    180deg,
    #fcdb9a 0%,
    #fad998 20%,
    #f3d292 40%,
    #e9c687 45%,
    #d9b578 50%,
    #c59f64 60%,
    #ac854c 70%,
    #997039 90%
  );
  box-shadow: 120px 0px 305px -183px rgba(255, 191, 63, 0.41);
  -webkit-box-shadow: 120px 0px 305px -183px rgba(255, 191, 63, 0.41);
  -moz-box-shadow: 120px 0px 305px -183px rgba(255, 191, 63, 0.41);
}

.PhoneInputCountrySelect {
  background: #232324 !important;
}

.PhoneInputCountrySelect option:hover {
  background: #fad998 !important;
}

.coin-marquee-header {
  display: none;
}

.coin-marquee-item {
  pointer-events: none;
}

.coin-marquee-wrapper--dark {
  background: #232324;
}

.coin-marquee-container {
  width: 100%;
}

.coin-marquee-container__inner {
  animation-duration: 60s !important;
}

.PhoneInputCountry {
  display: none !important;
}

input:disabled {
  background: none;
}

.bg-black {
  background-color: transparent !important;
}

.root-tree-element + ul {
  padding-top: 100px;
}

.root-tree-element + ul::before {
  height: 100px !important;
}

.contactsFaqBlock {
  background: linear-gradient(180deg, #1d2028 0%, #181a20 52.17%);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(
      119.29deg,
      rgba(202, 176, 36, 0.3) -2.1%,
      #fee30a 64.18%,
      #000000 64.62%,
      #4c450c 73.67%,
      #8d8016 93%
    );
  }
}

.balanceGradient {
  background: linear-gradient(
      0deg,
      var(--white-20, rgba(0, 0, 0, 0.2)),
      var(--white-20, rgba(0, 0, 0, 0.2))
    ),
    linear-gradient(
      147.04deg,
      rgba(0, 0, 0, 0) 63.25%,
      rgba(247, 234, 16, 0.15) 99.57%
    );
}

.referralBlockInfoGradient {
  background: linear-gradient(
      0deg,
      var(--white-20, rgba(0, 0, 0, 0.2)),
      var(--white-20, rgba(0, 0, 0, 0.2))
    ),
    linear-gradient(
      147.04deg,
      rgba(0, 0, 0, 0) 63.25%,
      rgba(247, 234, 16, 0.15) 99.57%
    );
}

.textShadow {
  text-shadow:
    1px 1px 2px black,
    0 0 1em black,
    0 0 0.2em black;
}

.overallBalanceGradient {
  background: linear-gradient(
      0deg,
      var(--white-20, rgba(0, 0, 0, 0.2)),
      var(--white-20, rgba(0, 0, 0, 0.2))
    ),
    linear-gradient(
      147.04deg,
      rgba(0, 0, 0, 0) 63.25%,
      rgba(247, 234, 16, 0.15) 99.57%
    );
}

.maintenanceGradient {
  background: linear-gradient(0deg, #131313, #131313),
    radial-gradient(66.11% 66.11% at 59.22% 33.89%, #131313 40%, #000000 100%);
}

.node-content {
  background-color: #333;
  padding: 10px;
  border-radius: 8px;
  color: white;
  text-align: center;
}

.node-header {
  font-weight: bold;
  color: #ffcc00;
}

.root {
  background-color: #ffcc00;
}

.children {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .node-content {
    font-size: 0.8em;
    padding: 8px;
  }
}

.balanceBG {
  background: linear-gradient(
      0deg,
      var(--Primary-Brand, #f7ea10),
      var(--Primary-Brand, #f7ea10)
    ),
    linear-gradient(
      123.23deg,
      rgba(255, 255, 255, 0.3) 2.76%,
      rgba(247, 234, 16, 0.3) 51.52%
    );
}

.detailedTable {
  backdrop-filter: blur(40px);
}

.cls-1 {
  fill: #666;
}
.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9,
.cls-10,
.cls-11,
.cls-12,
.cls-13 {
  stroke-width: 0px;
}
.cls-2 {
  fill: url(#linear-gradient);
}
.cls-14 {
  fill: url(#linear-gradient-16);
}
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-19,
.cls-20,
.cls-21,
.cls-22,
.cls-23,
.cls-24,
.cls-25,
.cls-26,
.cls-27 {
  stroke-miterlimit: 10;
}
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-19,
.cls-20,
.cls-21,
.cls-22,
.cls-23,
.cls-25,
.cls-26,
.cls-27 {
  stroke: #f7ea10;
}
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-19,
.cls-20,
.cls-21,
.cls-22,
.cls-23,
.cls-25,
.cls-26 {
  stroke-width: 2.063px;
}
.cls-15 {
  fill: url(#linear-gradient-7);
}
.cls-3 {
  fill: #040404;
}
.cls-4 {
  fill: url(#linear-gradient-6);
}
.cls-16 {
  fill: url(#linear-gradient-12);
}
.cls-17 {
  fill: url(#linear-gradient-8);
}
.cls-18 {
  fill: url(#linear-gradient-18);
}
.cls-18,
.cls-27 {
  stroke-width: 2px;
}
.cls-5 {
  fill: url(#linear-gradient-5);
}
.cls-6 {
  fill: #ff0;
}
.cls-7 {
  fill: #fff32d;
}
.cls-8 {
  fill: url(#linear-gradient-2);
}
.cls-9 {
  fill: url(#linear-gradient-20);
  fill-rule: evenodd;
}
.cls-19 {
  fill: url(#linear-gradient-17);
}
.cls-10 {
  fill: url(#linear-gradient-3);
}
.cls-20 {
  fill: url(#linear-gradient-13);
}
.cls-11 {
  fill: #bcbcbc;
}
.cls-21 {
  fill: url(#linear-gradient-15);
}
.cls-22 {
  fill: url(#linear-gradient-11);
}
.cls-23 {
  fill: url(#linear-gradient-14);
}
.cls-24 {
  fill: none;
  stroke: #040404;
}
.cls-25 {
  fill: url(#linear-gradient-9);
}
.cls-12 {
  fill: url(#linear-gradient-4);
}
.cls-13 {
  fill: aqua;
}
.cls-26 {
  fill: url(#linear-gradient-10);
}
.cls-27 {
  fill: url(#linear-gradient-19);
}
